import React, { useState, useEffect, Fragment } from "react";
import Papa from "papaparse";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { createCustomer, getFleetList } from "../../helper/api";
import {
  Form,
  Message,
  Modal,
  Icon,
  Header,
  Popup,
  Dropdown,
  Grid,
} from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import encrypt, { decrypt } from "../../helper/encryption";
import constants from "../../helper/constants.json";
import { Cookies } from "react-cookie";

function CreateCustomerTab(props) {
  let navigate = useNavigate();
  const cookies = new Cookies();
  const [activeItem, setActiveItem] = useState("");
  const [customerResult, setCustomerResult] = useState([]);
  const [enableSubmit, setEnableSubmit] = useState(true);
  const [message, setMessage] = useState("");
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [displayWarning, setDisplayWarning] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [unableToCreateCustomer, setUnableToCreateCustomer] = useState([]);
  const [getselectedGroup, setGetSelectedGroup] = useState(
    cookies.get(encrypt(constants.groupCookie)) !== undefined &&
      decrypt(cookies.get(encrypt(constants.groupCookie)))
        .toString()
        .trim()
  );

  const [groupList, setGroupList] = useState([]);

  const isCommercial =
    cookies.get(encrypt(constants.groupCookie)) !== undefined &&
    decrypt(cookies.get(encrypt(constants.groupCookie)))
      .toString()
      .includes("Commercial");

  const csvData = [
    [
      "email",
      "mobile",
      "first name",
      "last name",
      "rfid(e.g. 123C77D4)",
      "purpose (i.e. Operations)",
    ],
  ];

  const handleCsvChange = (event) => {
    // console.log(event.target.files[0]);
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        // console.log(results.data);
        setCustomerResult(results.data);
        setEnableSubmit(false);
      },
    });
  };

  useEffect(() => {
    if (isCommercial) {
      props.getFleetList("all").then(function (response) {
        if (response.data.respcode === 200) {
          // console.log(response.data.grouplist);
          const namesToFind = ["BYD", "AtlasCopco", "Skynet"];
          setGroupList(
            response.data.fleetList.filter((group) =>
              namesToFind.includes(group.value)
            )
          );
        }
      });
    }
  }, [isCommercial]);

  const handleChangeGetGroup = (event, { value }) => {
    setGetSelectedGroup(value);
  };

  const handleSubmit = () => {
    console.log({ data: customerResult, group: getselectedGroup });
    setIsLoading(true);
    props
      .createCustomer({
        data: customerResult,
        group: getselectedGroup,
      })
      .then(function (response) {
        console.log(response.data);
        if (response.data.respcode === 200) {
          setIsLoading(false);
          if (response.data.errors !== 0) {
            setDisplayWarning(true);
            var errorList = response.data.errors.map(function (el) {
              return "Email: " + el.email + ", Error Message: " + el.message;
            });
            setUnableToCreateCustomer(errorList);
          }

          if (response.data.description === "Successful") {
            setDisplaySuccess(true);
            setMessage(response.data.description);
          }
        }
      });
  };

  //Toggle messages to disappear after 3 seconds
  useEffect(() => {
    if (displaySuccess) {
      const timeId = setTimeout(() => {
        // After 3 seconds set the show value to false
        setDisplaySuccess(!displaySuccess);
      }, 3000);

      return () => {
        clearTimeout(timeId);
      };
    }

    if (open) {
      const timeId = setTimeout(() => {
        // After 3 seconds set the show value to false
        navigate("/");
      }, 2000);

      return () => {
        clearTimeout(timeId);
      };
    }
  });

  return (
    <Fragment>
      <Form
        onSubmit={handleSubmit}
        success={displaySuccess}
        warning={displayWarning}
        loading={loading}
      >
        <Form.Field required style={{ paddingTop: "5px" }}>
          <em>Note: Leave RFID Column Empty if no rfid</em>
        </Form.Field>
        <Form.Group>
          <Form.Input
            inline
            className="upload-csv"
            label="Upload CSV"
            type="file"
            accept=".csv"
            onChange={handleCsvChange}
            required
            // error={{
            //   content: "Only Csv files are allowed",
            //   pointing: "left",
            // }}
          />
          <Popup
            content={`Download Template`}
            trigger={
              <CSVLink
                data={csvData}
                filename={`template.csv`}
                style={{ paddingLeft: "20px", marginTop: "10px" }}
              >
                <Icon size="large" name="download" />
              </CSVLink>
            }
          />
        </Form.Group>
        <Grid style={{ paddingTop: "20px" }}>
          {isCommercial && (
            <Form.Field
              required
              style={{ marginLeft: "-190px", padding: "10px" }}
            >
              <label>Choose Group:</label>
              <Form.Group style={{ marginLeft: "300px", marginTop: "-30px" }}>
                <Dropdown
                  placeholder="Group"
                  search
                  selection
                  options={groupList}
                  onChange={handleChangeGetGroup}
                />
              </Form.Group>
            </Form.Field>
          )}
        </Grid>
        {unableToCreateCustomer.length !== 0 ? (
          <Message warning header={message} list={unableToCreateCustomer} />
        ) : (
          <Message success header="Form Submitted" content={message} />
        )}

        <Form.Button disabled={enableSubmit}>Submit</Form.Button>
      </Form>
      <Modal
        basic
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size="small"
      >
        <Header icon>
          <Icon name="sign-out" />
          401 Unauthorized Access, redirecting you to login page
        </Header>
      </Modal>
    </Fragment>
  );
}

export default connect(null, { createCustomer, getFleetList })(
  CreateCustomerTab
);
